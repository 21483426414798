<template>
  <div class="page">
    <Navbar title="项目经验" :callback="back" type="SMP" />
    <div class="list" v-for="project in projectList" :key="project.id">
      <van-row>
        <van-col span="20" class="name"
          >{{ project.name }}
          <van-tag :color="COLOR">{{ project.jobName }}</van-tag></van-col
        >
        <van-col span="4"
          ><van-tag :color="COLOR" plain @click="updateProject(project.seq)"
            >修改</van-tag
          >
        </van-col>
      </van-row>
      <van-row>
        <van-col span="20" class="time"
          ><van-icon name="todo-list-o" /> {{ project.startDate }}到{{
            project.endDate
          }}</van-col
        >
        <van-col span="4"
          ><van-tag type="danger" plain @click="deleteProject(project.seq)"
            >删除</van-tag
          ></van-col
        >
      </van-row>
    </div>
    <van-row class="no-record" v-if="projectList.length <= 0">
      <van-col span="24">
        <van-image
          width="103"
          height="103"
          :src="require('../../../../assets/images/home/no-record.png')"
        ></van-image
      ></van-col>
    </van-row>
    <van-row class="btn">
      <van-col span="24">
        <van-button
          :color="COLOR"
          icon="plus"
          size="small"
          @click="createProject()"
          >新增项目</van-button
        >
      </van-col>
    </van-row>
  </div>
</template>
<script>
import { Icon, Tag, Image } from 'vant'
import Navbar from '../../common/Navbar.vue'
export default {
  components: {
    Navbar: Navbar,
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Image.name]: Image
  },
  data () {
    return {
      projectList: []
    }
  },
  mounted () {
    var query = this.$route.query
    this.personCode = query.personCode
    this.retrieveProjectList()
  },
  methods: {
    async retrieveProjectList () {
      var pd = { personCode: this.personCode, group: 'PJT', type: 'DTL' }
      this.projectList = []
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var content = JSON.parse(element.content)
          var project = {
            seq: element.seq,
            name: content.name,
            description: content.description,
            jobName: content.jobName,
            jobContent: content.jobContent,
            startDate: content.startDate,
            endDate: content.endDate
          }
          this.projectList.push(project)
        })
      }
    },
    createProject () {
      this.$router.push({ path: '/mde/people/person/dtlProjectEdit', query: { personCode: this.personCode, seq: this.projectList.length + 1 } })
    },
    updateProject (seq) {
      this.$router.push({ path: '/mde/people/person/dtlProjectEdit', query: { personCode: this.personCode, seq: seq } })
    },
    deleteProject (id) {
      this.$dialog.confirm({
        title: '删除确认',
        message: '确认删除项目吗?'
      })
        .then(() => {
          this.deleteProjectExecute(id)
        })
    },
    async deleteProjectExecute (seq) {
      var pd = { key: 'PSN_PJT_ITEM', personCode: this.personCode, type: 'DTL', seq: seq }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/deletePersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.retrieveProjectList()
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: '删除失败:' + res.msg
        })
      }
    },
    back () {
      var url = window.sessionStorage.getItem(this.SESSION_BACKURL)
      window.location.href = url
    }
  }
}
</script>
<style lang="less" scoped>
.list {
  padding: 10px 20px;
  height: 40px;
  border-bottom: 1px solid #eee;
  text-align: left;
  .name {
    font-size: 14px;
    font-weight: 600;
    height: 20px;
    line-height: 20px;
  }
  .time {
    height: 20px;
    line-height: 20px;
  }
}
.btn {
  margin-top: 10px;
}
</style>
